import { ElTag } from 'element-plus'
import { h } from 'vue'
import { enumToOption } from '@/utils'

// 客户类型
export enum customerType {
  公司 =1,
  集团,
  个人商户
}

export const customerTypeOpt = enumToOption(customerType)

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'enterpriseCode',
      label: '企业编号',
      width: '140'
    },
    {
      key: 'customerType',
      label: '客户类型',
      width: '100',
      formatter: (row, column, val) => customerType[val] || '-'
    },
    {
      key: 'abbrName',
      label: '企业名称(简称)',
      width: '140'
    },
    {
      key: 'collectivized',
      label: '含有子公司',
      width: '140',
      formatter: (row) => {
        return row.collectivized === 0 ? '否' : '是'
      }
    },
    {
      key: 'contacts',
      label: '联系人',
      width: '140'
    },
    {
      key: 'contactsPhone',
      label: '联系电话',
      width: '140'
    },
    {
      key: 'contactsAddress',
      label: '联系地址',
      width: '140',
      formatter: (row) => {
        return row.contactsArea
      }
    },
    {
      key: 'enterpriseStatus',
      label: '状态',
      width: '100',
      formatter: (row, col, val) => {
        const obj = {
          1: '正常',
          2: '禁用'
        }
        return h(ElTag, {
          size: 'medium',
          type: val === 1 ? 'success' : 'danger'
        }, obj[val])
      }
    },
    {
      key: 'domainHeader',
      label: '定制域名',
      width: '140'
    },
    {
      key: 'signTime',
      label: '签订日期',
      width: '150'
    },
    {
      key: 'trialEndTime',
      label: '试用有效期',
      width: '150'
    },
    {
      key: 'formalEndTime',
      label: '正式有效期',
      width: '150'
    },
    {
      key: 'carUsedFee',
      label: '车辆使用费',
      width: '100',
      formatter: (row) => {
        return h('span', {
          style: row.carUsedFee < 1000 ? 'color:red' : ''
        }, row.carUsedFee)
      }
    },
    {
      key: 'operator',
      label: '操作人',
      width: '140'
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '150'
    },
    {
      key: 'updateTime',
      label: '更新时间',
      width: '150'
    },
    {
      key: 'action',
      label: '操作',
      width: '140',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'input',
      label: '企业编号',
      key: 'enterpriseCode',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 300,
      maxlength: 18,
      rules: {}
    },
    {
      type: 'input',
      label: '企业名称',
      key: 'name',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 50,
      rules: {}
    },
    {
      type: 'select',
      label: '状态',
      key: 'enterpriseStatus',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '禁用',
          value: 2
        }
      ],
      rules: {
        type: 'number'
      }
    },
    {
      type: 'slot',
      // label: '状态',
      key: 'data',
      placeholder: '请选择',
      labelWidth: 0,
      width: 100,
      inputWidth: 200
    },
    {
      type: 'slot',
      label: '车辆使用费',
      key: 'moneyCar',
      placeholder: '请输入',
      labelWidth: 100,
      inputWidth: 200,
      maxlength: 50,
      rules: {

      }
    },
    {
      type: 'select',
      label: '客户类型',
      key: 'customerType',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: customerTypeOpt,
      rules: {
        type: 'number'
      }
    }

  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    visible: false, // 弹框显示
    isPaymentOperation: false, // 缴费弹框
    isAffiliatedSubsidiaries: false,
    isBankAPPID: false,
    isRenewal: false, // 续签弹框
    title: '新建员工',
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}

export const economicsStatusMap = {
  1: '营业',
  2: '停业',
  3: '整改',
  4: '停业整顿',
  5: '歇业',
  6: '注销',
  7: '其他'
}

export const economicsTypeMap = {
  1: '国有经济',
  2: '集体经济',
  3: '私营经济',
  4: '个体经济',
  5: '联营经济',
  6: '股份制',
  7: '外商投资',
  8: '港澳台投资',
  9: '其他经济'
}
export const labelOptions = [
  {
    label: '试用有效期',
    value: '1'
  },
  {
    label: '正式有效期',
    value: '2'
  }
]
export const moneyConditionOptions = [
  {
    label: '大于等于',
    value: '1'
  },
  {
    label: '小于等于',
    value: '2'
  },
  {
    label: '等于',
    value: '3'
  }
]
export const rules = {
  enterpriseCode: {
    min: 1,
    max: 50,
    message: '支持最多50个字符输入',
    trigger: 'blur'
  },
  collectivized: [{
    required: true,
    message: '必填',
    trigger: 'blur'
  }],
  kflx: [{
    required: true,
    message: '必填',
    trigger: 'blur'
  }],
  abbrName: [
    {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    {
      min: 1,
      max: 8,
      message: '支持1-8个字符输入',
      trigger: 'blur'
    }
  ],
  systemName: [
    {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    {
      min: 1,
      max: 8,
      message: '支持1-8个字符输入',
      trigger: 'blur'
    }
  ],
  domainHeader: [
    {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    {
      min: 1,
      max: 16,
      message: '支持1-16个小写字母和数字输入',
      trigger: 'blur'
    },
    {
      type: 'string',
      pattern: /^[a-z0-9]*$/g,
      message: '支持1-16个小写字母和数字输入'
    }

  ],
  name: [
    {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  orgCode: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  manageCard: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  manageCardNum: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  cardOrg: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  contactsAddress: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  manageScope: [
    {
      min: 1,
      max: 500,
      message: '支持最多500个字符输入',
      trigger: 'blur'
    }
  ],
  signMoney: [
    {
      min: 1,
      max: 20,
      message: '支持最多20个字符输入',
      trigger: 'blur'
    },
    {
      type: 'string',
      pattern: /^([0-9]*(\.)?([0-9]{1,2})?)$/,
      message: '请输入数字且最多两位小数'
    }
  ],
  signAddress: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  contacts: [
    {
      min: 0,
      max: 10,
      message: '支持最多10个字符输入',
      trigger: 'blur'
    }
  ],
  contactsPhone: [
    {
      min: 1,
      max: 11,
      message: '支持最多11个字符输入',
      trigger: 'blur'
    },
    {
      type: 'string',
      pattern: /^[0-9]+$/,
      message: '请输入数字'
    }
  ],
  legalPerson: [
    {
      min: 1,
      max: 50,
      message: '支持最多50个字符输入',
      trigger: 'blur'
    }
  ],
  enterpriseStatus: [
    {
      required: true,
      message: '必填',
      trigger: 'blur'
    }
  ]
}
