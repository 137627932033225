
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getToken } from '@/utils/auth'
import { getFileUrl } from '@/api/common'
export default defineComponent({
  name: 'CUpload',
  props: {
    // 上传图片的地址
    action: {
      type: String,
      default: ''
    },
    // 设置上传的请求头部
    headers: {
      type: Object,
      default: () => {
        return {
          token: getToken(),
          grayVersion: 'W_asset_709'
        }
      }
    },
    // 最大允许上传个数
    limit: {
      type: Number,
      default: 1
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 上传的文件列表, 例如: [{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 限制上传文件大小 KB
    maxSize: {
      type: Number,
      default: 500
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: false
    },
    // 接受上传的文件类型（thumbnail-mode 模式下此参数无效）
    accept: {
      type: String,
      default: '.jpg,.png,.jpeg'
    },
    // 文件列表的类型 text/picture/picture-card
    listType: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const mb = Number.isInteger(props.maxSize / 1024)
      ? props.maxSize / 1024
      : (props.maxSize / 1024).toFixed(1)
    const upload = ref<HTMLElement | null>()
    const data = reactive({
      fileType: props.accept.replace(/,/g, '、'),
      maxSizeView: props.maxSize >= 1024 ? mb + ' MB' : props.maxSize + ' KB',
      currentPicUrl: '',
      isViewer: false,
      isImg: false
    })

    /**
     * @description: 文件列表移除文件时的钩子
     * @param {*} file
     * @param {*} fileList
     * @return {void}
     */
    const handleRemove = (file, fileList) => {
      console.log(file, fileList)
      emit('on-remove', file, fileList)
    }

    /**
     * @description: 点击文件列表中已上传的文件时的钩子
     * @param {*} file
     * @return {void}
     */
    const handlePreview = file => {
      if (data.isImg && file.status === 'success') {
        data.currentPicUrl = file.response.data.url
        data.isViewer = !data.isViewer
      } else if (file.isImg && file.status === 'edit') {
        data.currentPicUrl = file.url
        getFileUrl({ filename: file.url }).then(res => {
          data.currentPicUrl = res.data.url
          data.isViewer = !data.isViewer
        })
      }
      // console.log(file)
    }

    /**
     * @description: 文件超出个数限制时的钩子
     * @param {*} files
     * @param {*} fileList
     * @return {void}
     */
    const handleExceed = (files, fileList) => {
      console.log(files, fileList)
      ElMessage.warning(`您最多只能上传 ${props.limit} 个文件`)
    }

    /**
     * @description: 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除
     * @param {*} file
     * @param {*} fileList
     * @return {void}
     */
    const beforeRemove = (file, fileList) => {
      console.log(file, fileList)
      // return ElMessageBox.confirm(`确定移除 ${file.name} ${fileList}？`)
    }

    /**
     * @description: 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传
     * @param {*} file
     * @param {*} fileList
     * @return {void}
     */
    const handleBeforeUpload = file => {
      // 限制上传文件大小
      const isSize = file.size / 1024 < props.maxSize
      if (!isSize) {
        ElMessage.warning(`上传文件最大不超过 ${data.maxSizeView}`)
        return false
      }

      // 限制上传文件类型
      const currentFileType = file.name.substring(file.name.lastIndexOf('.'))
      const acceptArr = props.accept.split(',')
      if (!acceptArr.includes(currentFileType)) {
        ElMessage.warning('上传文件类型不匹配')
        return false
      }
    }

    /**
     * @description: 文件上传成功时的钩子
     * @param {*} response
     * @param {*} file
     * @return {void}
     */
    const handleSuccess = (response, file) => {
      console.log(upload.value)
      if (response.success) {
        ElMessage.success('上传成功')
        emit('on-success', file)
      }
    }

    /**
     * @description: 文件上传失败时的钩子
     * @param {*} err
     * @param {*} file
     * @return {void}
     */
    const handleError = (err, file) => {
      console.log(err, file)
      ElMessage.error('上传失败')
    }

    /**
     * @description: 关闭图片预览
     * @param {*}
     * @return {*}
     */

    const handleViewerClose = () => {
      data.isViewer = !data.isViewer
    }

    onMounted(() => {})

    return {
      ...toRefs(data),
      handleRemove,
      handlePreview,
      handleExceed,
      beforeRemove,
      handleBeforeUpload,
      handleSuccess,
      handleError,
      handleViewerClose,
      upload
    }
  }
})
